import {IMember} from "../models/member";
import {DefaultTheme} from "styled-components";
import {ICityLiteAsset} from "../models/Assets/ICityAsset";
import {ITranslation} from "../translations/trInterface";
declare module "styled-components" {
    export interface DefaultTheme {
        handleState?: () => void;
        protocol?: "http" | "https" | "wss" | "ws";
        host?: string;
        requestUri?: string;
        domain?: string;
        hostNoSubDomain?: string;
        isUserLocationCountryAllowed?: boolean;
        translations?: ITranslation;
        direction?: "rtl" | "ltr";
        isLTR?: boolean;
        isRTL?: boolean;
        country?: string;
        cityId?: number;
        locale: "ar" | "en";
        redirectUrl?: string;
        completionUrl?:string;
        otp?: string;
        otp_number?: string;
        isLoggedIn?: boolean;
        isRegistered?: boolean
        isMobile?: boolean;
        isDesktop?: boolean;
        device?: string;
        memberData?: IMember;
        city?:ICityLiteAsset;
        LoginMember?: (
            state: boolean,
            redirect?: string,
            dialogType?: string,
            redirectOnClose?: boolean,
            credential?: { [key: string]: string },
            ImmediateCb?: {},
            onContinueCb?: {}
        ) => Promise<void>;
        redirectOnLogin?: string;
        redirectOnClose?: boolean;
        url?: string;
        ShowSnackbar?: (state: boolean, message?: string, type?: string) => void;
        currentTab?: string;
        updateCurrentTab?: (tab: string) => void;
        fullPage?: true;
    }
}
const defaultTheme: DefaultTheme = {
    locale: "ar",
    direction: "rtl",
    isLTR: false,
    isRTL: true,
    isLoggedIn: false,
    isRegistered: false,
    isMobile: false,
    isDesktop: false,
    device: 'desktop',
    redirectUrl: "",
    url: "",
    country: 'jo',
}

export const updateThemeMemberData = (data: IMember) => {
    defaultTheme.memberData = data;
};

export default defaultTheme;
