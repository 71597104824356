import {fetchAtClientSide} from "../../../utils/misc/fetchClient";

interface ILogoutBody {
    GulpinRegister?: { token: string; channel: string; data_only: boolean };
}

export const logout = async (fcmToken: string): Promise<any> => {
    const body: ILogoutBody = fcmToken
        ? {
            GulpinRegister: {
                token: decodeURIComponent(fcmToken),
                channel: "firebase",
                data_only: true,
            },
        }
        : {};

    // body will be follow ILogout type when send firebase token
    const url = `auths/logout`;
    await fetchAtClientSide({url, body, method: "POST"}).catch((e) => {
        console.warn("Logout Api Error", e.message);
    });
};
