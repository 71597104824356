import { useEffect, useState } from "react";
import {UserIdentity } from "../interfaces";
import Auth from "../auth";
import { fetchAtClientSide } from "../utils/misc/fetchClient";

function Test() {
  const [JWTToken, setJWTToken] = useState("");
  const [auth, setAuth] = useState<Auth | null>(null);
  const [userInfo, setUserInfo] = useState<UserIdentity | null>(null);
  useEffect(() => {
    async function initAuth() {
      const newAuth = Auth.getInstance();
      setAuth(newAuth);
    }

    initAuth().then();
  }, []);

  const handleAuthenticate = async () => {
    if (!auth) return;
    await auth.authenticate("https://jo.sooqtest.com");
  };
  const fullPageWithVerified = async () => {
    if (!auth) return;
    await auth.authenticate("https://jo.sooqtest.com" , true);
  };

  const handleGetIdentity = async () => {
    if (!auth) return;
    const identity = await auth.getIdentity();
    setUserInfo(identity);
    return identity;
  };

  const handleGenerateToken = async () => {
    if (!auth) return;
    const token = await auth.getToken(true);
    setJWTToken(token);
  };
  const handleGenerateTokenVerified = async () => {
    if (!auth) return;
      auth.getToken(true, true).then((token)=> {
        handleGetIdentity();
          setJWTToken(token);
    })
  };

  const handleLogout = async () => {
    if (!auth) return;
    try {
      await auth.destroy();
      window.location.reload();
      console.log("Successfully Logout");
    } catch (e) {
      console.error("error", e);
    }
  };

  const sendEmail = async () => {
    const memebr = await handleGetIdentity();
    await fetchAtClientSide({
      url: "slr/email",
      body: {
        email: memebr?.M_new_email,
      },
      method: "POST",
    });
  };
  return (
    <>
      <div>
        <button onClick={handleAuthenticate}>Authenticate</button>
      </div>
      <div>
        <button className="blueBtn p-16" onClick={handleGenerateToken}>
          Generate JWT
        </button>
        <div style={{wordBreak:"break-word"}}>{JWTToken}</div>
      </div>
      <div>
        <button onClick={handleGenerateTokenVerified}>Verified token</button>
      </div>
      <div>
        <button onClick={handleGetIdentity}>get identity</button>
        <div>{userInfo && JSON.stringify(userInfo)}</div>
      </div>
        <div>
        <button onClick={fullPageWithVerified}>FullPage verified</button>
      </div>
      <div>
        <button onClick={handleLogout}>Logout</button>
      </div>
      <div>
        <button onClick={() => Auth.getInstance().changePassword()}>
          Change Password
        </button>
      </div>
      <div>
        <button onClick={() => Auth.getInstance().changeMobilePhone()}>
          Change Phone Number
        </button>
      </div>
        <div>
            <button onClick={() => Auth.getInstance().completeUserProfileInfo()}>
                Complete profile data
            </button>
        </div>
      <br />
      <br />
      <h3>Verify Email</h3>
      <div>
        <button onClick={sendEmail}>Send Email</button>
      </div>
        <div>
            <button onClick={async () => {
                await handleGenerateToken();
                console.log("button 1")
            }}>Button 1
            </button>
            <button onClick={async () => {
                await handleGenerateToken();
                console.log("button 2")
            }}>Button 2
            </button>
            <button onClick={async () => {
                await handleGenerateToken();
                console.log("button 3")
            }}>Button 3
            </button>
        </div>
    </>
  );
}

export default Test;
