import {
    ICountryAssetLite,
} from "../../models/Assets/ICountryAsset";

export const filterCountryByCountryCode = (
    allCountries: ICountryAssetLite[],
    phoneCode: string
): ICountryAssetLite => {
    return (
        allCountries &&
        allCountries.filter((c) => c.phone_code === phoneCode)[0]
    );
};

export const getCountryByCountryAbbreviation = (countries:ICountryAssetLite[],countryAbbreviation:string)=> {
    return countries && countries.filter((c)=> c.abbreviation === countryAbbreviation)[0]
}
