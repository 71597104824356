import {ITranslation} from "./trInterface";

const english: ITranslation = {
    LoginOrRegister: "Login or Register",
    help: "Help?",
    bestWay:"The best way to ",
    buyOrSell:"Sell or Buy Anything",
    homeWelcome1: "Join millions of people using OpenSooq",
    homeWelcome2: "Promote trust and safety by logging in",
    homeWelcome3: "Check and respond to chats, replies and offers",
    homeWelcome4: "Manage your favorite and saved listings",
    homeWelcome5: "Earn money and manage your listings",
    enter: "Enter",
    mobileNumber: 'Mobile Number',
    next: "Next",
    byUsingOpenSooq: "By using OpenSooq you agree to ",
    termsOfUse: "Terms of Use",
    and: "and",
    contentPolicy: "Content Policy",
    password: "Password",
    tryAgain: "Try Again",
    pleaseFill: "Please fill",
    forgetPassword: "Forgot Password?",
    rememberMe: "Remember Me",
    loggedInSuccessfully: "Logged in Successfully",
    passChangedSuccessfully: "Password Changed Successfully",
    logoutFromAllDevices: "You have been logged out from all other devices using this account",
    AddListingOrContinueBrowsing: "Add a listing or continue browsing",
    continue: "Continue",
    addListing: "Add Listing",
    verifyMobileNumber: "Verify your Mobile Number",
    verifyMobileNumberP: "To build trust and protect users as per local laws and regulations, please verify your mobile number ",
    VerifyViaWhatsApp: "Verify via WhatsApp",
    VerifyViaSMS: "Verify via SMS",
    VerifyViaViber: "Verify via Viber",
    VerificationCode: "Verification Code",
    VerificationCodeP: "Please enter the code your received on your WhatsApp or SMS.",
    registrationCompleted: "Registration Completed",
    nowVerified: "Your mobile number is now verified [MOBILE_NUM]",
    resend: "Resend",
    changePassword: "Change Password",
    newPassword: "New Password",
    newPassword2: "ُEnter New Password",
    confirmNewPassword: "Confirm New Password",
    currentPassword: "Current Password",
    save: "Save",
    forgotPassword: "Forgot Password",
    forgotPasswordP: "We will send you a code or link to the mobile number below to reset your password.",
    completeRegistration: "Complete Registration",
    completeRegistrationP: "Please fill the form to complete your registration. This will allow you to add listings and chat with others.",
    uploadPhoto: "Upload your account photo",
    email: "Email",
    changeMobileNumber: "Change Mobile Number",
    changeMobileNumberP: "Add new mobile number to verify",
    yes: "Yes",
    no: "No",
    mobileNumberUsed: "Mobile Number is used in another account",
    mobileNumberUsedP: "Do you want to logout from this account and login to another account?",
    searchForCountry: "Search for country",
    doYouNeedHelp: "Do you need help?",
    fullName: "Full Name",
    whatsapp: "WhatsApp",
    contactUsNow: "Contact Us Now",
    contactUs: "Contact Us",
    complete: "Complete",
    later: "Later",
    accountType:"Account Type",
    accountTypeTitle_1:"Personal Use",
    accountTypeTitle_2:"Business/Professional Use",
    accountType_p1:"For non-commercial use. Ideal for individuals buying and selling a few personal items.",
    accountType_p2:"For commercial use or for your company. Ideal for businesses or professionals listing multiple items, renting properties, or offering services.",
    openSooqSso: {
        title: "Login - OpenSooq"
    },
    verify:"Verify",
    birthOfDate:"Date of Birth",
    selectDateOfBirth : "Select Date of Birth",
    gender:"Gender",
    male:"Male",
    female:"Female",
    selectGender:"Select Gender",
    enterYourEmail:"Enter Email",
    completeYourProfile:"Complete your profile",
    provideYourInfo:"Providing this additional information will help OpenSooq to enhance your personalized experience.",
    editProfileConfirmationMessage : "Are you sure you want update your profile?",
    enterValidEmail: "Invalid email",
    verifyEmailSuccessMsg:"the verification email has been sent, please check your email",
    birthdayValidation:"You must be older than 10 Years Old",
    defaultDateValue: "Day /  Month / Year",
    pleaseEnterAValidEmail:"Please enter the email"
};

export default english;
