import * as cookie from "cookie";
import {configNavigationSso, CookiesConstants, SsoScreenTypes} from "../constants";

export function b64_safe(input: string | Uint8Array): string {
    let str = '';

    if (typeof input == 'string')
        str = input;
    else
        str = Buffer.from(input).toString();

    return str.replaceAll(/\+/g, "-").replaceAll(/\//g, "_").replaceAll(/=/g, "");
}

export function getUtf8Bytes(str: string): Uint8Array {
    return new Uint8Array(
        Array.from(unescape(encodeURIComponent(str)), (c) => c.charCodeAt(0))
    );
}

export function uint8tohex(a: Uint8Array): string {
    return Array.from(a).map((b) => b.toString().padStart(2, "0")).join("");
}

export function uint8tob64(a: Uint8Array): string {
    return btoa(String.fromCharCode.apply(null, Array.from(a)));
}

export function atob64(input: string): string {
    return uint8tob64(getUtf8Bytes(input));
}

const browser_hashes: { [key: string]: string } = {
    sha256: 'SHA-256',
};

export async function hmac(key: string, msg: string, digest?: string, alg?: string) {
    if (!digest) digest = "base64";
    if (!alg) alg = "sha256";
    const hash = browser_hashes[alg];
    if (!hash) throw new Error("not supported");
    const keyBytes = getUtf8Bytes(key);
    const messageBytes = getUtf8Bytes(msg);

    const cryptoKey = await crypto.subtle.importKey(
        "raw", keyBytes, {name: "HMAC", hash: hash},
        true, ["sign"],
    );
    const sig = new Uint8Array(await crypto.subtle.sign("HMAC", cryptoKey, messageBytes));
    if (digest == "hex") return uint8tohex(sig);
    else if (digest == "base64") return uint8tob64(sig);
    return sig;
}

export function rnd(length: number): string {
    const a = [];
    for (let i = 0; i < length; ++i) a.push(Math.floor(Math.random() * 16));
    return a.map((b) => b.toString()).join("");
}

export const jsonSafeGet = <T>(jsonString, defaultData = {}): T => {
    let output = defaultData as T;
    try {
        output = JSON.parse(jsonString);
    } catch (e) {
        // do nothing.
    }
    return output;
};

export function isBrowser(): boolean {
    return typeof window !== "undefined";
}


export const defaultOptions = {
    expires: new Date(
        new Date(new Date().getTime() + 31536000000).toUTCString()
    ),
    path: "/",
    ...(typeof window !== "undefined"
        ? document.location.hostname.includes("localhost")
            ? {domain: document.location.hostname}
            : {
                domain:
                    "." +
                    document.location.hostname.split(".").reverse()[1] +
                    "." +
                    document.location.hostname.split(".").reverse()[0],
            }
        : {}),
};

export const setCookie = (name: string, value: string | undefined, /*expirationDays: number = 0*/options: cookie.CookieSerializeOptions = defaultOptions): void => {
    document.cookie = cookie.serialize(name, value, options);
}
export const destroyCookie = (name: string,
                              options: cookie.CookieSerializeOptions = defaultOptions): void => {
    // document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    let cookieOptions: cookie.CookieSerializeOptions = {
        ...(options || {}),
        maxAge: -1,
        httpOnly: false,
    };
    if (isBrowser()) {
        cookieOptions = {
            ...defaultOptions,
            ...(options || {}),
            expires: new Date("2013"),
            maxAge: -1,
        };
    }
    /**
     * We forward the request destroy to setCookie function
     * as it is the same function with modified maxAge value.
     */
    return setCookie(name, undefined, cookieOptions);
}
export const getCookie = (name: string): string => {
    const cookieStr = document.cookie;
    if (!cookieStr) return '';

    const cookies = cookieStr.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const [cookieName, cookieValue] = cookies[i].trim().split("=");
        if (cookieName === name) {
            return decodeURIComponent(cookieValue);
        }
    }

    return '';
}

/**
 * Parses cookies.
 *
 * @param options Options that we pass down to `cookie` library.
 */
export function parseCookies(
    options: cookie.CookieParseOptions = {},
) {

    // todo @abdulraheem fix me
    const NEXT_PUBLIC_DEFAULT_COOKIES_EXPIRED_IN_MINUTES = 1000;
    const defaultOptions = {
        expires: new Date(
            new Date().getTime() +
            Number(NEXT_PUBLIC_DEFAULT_COOKIES_EXPIRED_IN_MINUTES) * 60000
        ),
        path: "/",
        ...(typeof window !== "undefined"
            ? window.location.hostname.includes("localhost")
                ? {domain: window.location.hostname}
                : {
                    domain:
                        "." +
                        window.location.hostname.split(".").reverse()[1] +
                        "." +
                        window.location.hostname.split(".").reverse()[0],
                }
            : {}),
    };

    return cookie.parse(document.cookie, {...defaultOptions, ...options});
}

const reg = /^\/(ar|en(-[A-Z]{2})?)\/?/; // Adjust regex to match locales like "en", "en-US", "ar", "ar-EG"

export const getLang = () => {
    if (typeof window === "undefined") {
        // Return locale from cookies if we're on the server
        const cookies = parseCookies();
        return cookies[CookiesConstants.LOCALE] || "ar"; // Default to "ar" if cookie locale is not set
    }

    const { pathname } = window.location;
    const match = pathname.match(reg);
    if (match) {
        const locale = match[1]; // Extract matched locale part
        const langArr = locale.split("-"); // Split locale if it includes a region
        return langArr[0]; // Return base language code
    } else if (!match && typeof window !== "undefined") {
        const cookies = parseCookies();
        return cookies[CookiesConstants.LOCALE] || "ar"; // Default to "ar" if cookie locale is not set
    }

    // Return default language if pathname does not match the regex
    return "ar";
};

export const capitalized = (lbl) => {
    return (lbl || "").replace(
        /\w+/g,
        (a) => a[0].toUpperCase() + a.substring(1)
    );
};
export const labelHelper = (
    object?: any,
    locale: string = null,
    specific_field: string = null
) => {
    locale = locale || getLang();
    return labelHelperInternal(locale, object, specific_field);
};

export function classNames(...args) {
    let output = [];
    for (let i = 0; i < args.length; i++) {
        if (args[i]) output.push(args[i]);
    }
    return {className: output.join(" ")};
}

const labelHelperInternal = (
    locale: string,
    object?: any,
    specific_field: string = null
) => {
    if (typeof object === "string") return object;
    if (!object) return "";
    if (specific_field) {
        return locale === "en"
            ? object[`${specific_field}_${locale}`]
            : object[`${specific_field}_${locale}`] || object[specific_field];
    }
    return locale === "en"
        ? object.en ||
        object.label_en ||
        object.name_en ||
        object.name_english ||
        object.title_en ||
        object.label ||
        object.name ||
        object.title ||
        object.url_en ||
        object.make_uri_en ||
        object.model_uri_en ||
        object.option_label_en ||
        ""
        : object.ar ||
        object.label_ar ||
        object.name_ar ||
        object.name_arabic ||
        object.title_ar ||
        object.label ||
        object.name ||
        object.title ||
        object.url_ar ||
        object.make_uri_ar ||
        object.model_uri_ar ||
        object.option_label_ar ||
        "";
};

export function isClientSide(): Boolean {
    return typeof window !== "undefined";
}

export const getQueryStringValue = (key: string): string => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(key) as string;
}

export const getPrevScreen = (
  screensFlow: SsoScreenTypes[],
  myScreen: SsoScreenTypes
) => {
  const index = screensFlow?.indexOf(myScreen);
  return screensFlow?.[index - 1];
};

export const getNavigateType = (myScreen: SsoScreenTypes) : "close" | "back" => {
  return configNavigationSso[myScreen] || "close";
};

export const trimSpacesFromPassword = (inputValue) => {
    return  inputValue.replace(/\s+/g, '');
}

export const normalizeText = (word: string): string => {
    let regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
    return (
        word
            .replaceAll("\u0610", "") //ARABIC SIGN SALLALLAHOU ALAYHE WA SALLAM
            .replaceAll("\u0611", "") //ARABIC SIGN ALAYHE ASSALLAM
            .replaceAll("\u0612", "") //ARABIC SIGN RAHMATULLAH ALAYHE
            .replaceAll("\u0613", "") //ARABIC SIGN RADI ALLAHOU ANHU
            .replaceAll("\u0614", "") //ARABIC SIGN TAKHALLUS

            //Remove koranic anotation
            .replaceAll("\u0615", "") //ARABIC SMALL HIGH TAH
            .replaceAll("\u0616", "") //ARABIC SMALL HIGH LIGATURE ALEF WITH LAM WITH YEH
            .replaceAll("\u0617", "") //ARABIC SMALL HIGH ZAIN
            .replaceAll("\u0618", "") //ARABIC SMALL FATHA
            .replaceAll("\u0619", "") //ARABIC SMALL DAMMA
            .replaceAll("\u061A", "") //ARABIC SMALL KASRA
            .replaceAll("\u06D6", "") //ARABIC SMALL HIGH LIGATURE SAD WITH LAM WITH ALEF MAKSURA
            .replaceAll("\u06D7", "") //ARABIC SMALL HIGH LIGATURE QAF WITH LAM WITH ALEF MAKSURA
            .replaceAll("\u06D8", "") //ARABIC SMALL HIGH MEEM INITIAL FORM
            .replaceAll("\u06D9", "") //ARABIC SMALL HIGH LAM ALEF
            .replaceAll("\u06DA", "") //ARABIC SMALL HIGH JEEM
            .replaceAll("\u06DB", "") //ARABIC SMALL HIGH THREE DOTS
            .replaceAll("\u06DC", "") //ARABIC SMALL HIGH SEEN
            .replaceAll("\u06DD", "") //ARABIC END OF AYAH
            .replaceAll("\u06DE", "") //ARABIC START OF RUB EL HIZB
            .replaceAll("\u06DF", "") //ARABIC SMALL HIGH ROUNDED ZERO
            .replaceAll("\u06E0", "") //ARABIC SMALL HIGH UPRIGHT RECTANGULAR ZERO
            .replaceAll("\u06E1", "") //ARABIC SMALL HIGH DOTLESS HEAD OF KHAH
            .replaceAll("\u06E2", "") //ARABIC SMALL HIGH MEEM ISOLATED FORM
            .replaceAll("\u06E3", "") //ARABIC SMALL LOW SEEN
            .replaceAll("\u06E4", "") //ARABIC SMALL HIGH MADDA
            .replaceAll("\u06E5", "") //ARABIC SMALL WAW
            .replaceAll("\u06E6", "") //ARABIC SMALL YEH
            .replaceAll("\u06E7", "") //ARABIC SMALL HIGH YEH
            .replaceAll("\u06E8", "") //ARABIC SMALL HIGH NOON
            .replaceAll("\u06E9", "") //ARABIC PLACE OF SAJDAH
            .replaceAll("\u06EA", "") //ARABIC EMPTY CENTRE LOW STOP
            .replaceAll("\u06EB", "") //ARABIC EMPTY CENTRE HIGH STOP
            .replaceAll("\u06EC", "") //ARABIC ROUNDED HIGH STOP WITH FILLED CENTRE
            .replaceAll("\u06ED", "") //ARABIC SMALL LOW MEEM

            //Remove tatweel
            .replaceAll("\u0640", "")

            //Remove tashkeel
            .replaceAll("\u064B", "") //ARABIC FATHATAN
            .replaceAll("\u064C", "") //ARABIC DAMMATAN
            .replaceAll("\u064D", "") //ARABIC KASRATAN
            .replaceAll("\u064E", "") //ARABIC FATHA
            .replaceAll("\u064F", "") //ARABIC DAMMA
            .replaceAll("\u0650", "") //ARABIC KASRA
            .replaceAll("\u0651", "") //ARABIC SHADDA
            .replaceAll("\u0652", "") //ARABIC SUKUN
            .replaceAll("\u0653", "") //ARABIC MADDAH ABOVE
            .replaceAll("\u0654", "") //ARABIC HAMZA ABOVE
            .replaceAll("\u0655", "") //ARABIC HAMZA BELOW
            .replaceAll("\u0656", "") //ARABIC SUBSCRIPT ALEF
            .replaceAll("\u0657", "") //ARABIC INVERTED DAMMA
            .replaceAll("\u0658", "") //ARABIC MARK NOON GHUNNA
            .replaceAll("\u0659", "") //ARABIC ZWARAKAY
            .replaceAll("\u065A", "") //ARABIC VOWEL SIGN SMALL V ABOVE
            .replaceAll("\u065B", "") //ARABIC VOWEL SIGN INVERTED SMALL V ABOVE
            .replaceAll("\u065C", "") //ARABIC VOWEL SIGN DOT BELOW
            .replaceAll("\u065D", "") //ARABIC REVERSED DAMMA
            .replaceAll("\u065E", "") //ARABIC FATHA WITH TWO DOTS
            .replaceAll("\u065F", "") //ARABIC WAVY HAMZA BELOW
            .replaceAll("\u0670", "") //ARABIC LETTER SUPERSCRIPT ALEF

            //Replace Waw Hamza Above by Waw
            .replaceAll("\u0624", "\u0648")

            //Replace Ta Marbuta by Ha
            .replaceAll("\u0629", "\u0647")

            //Replace Ya
            // and Ya Hamza Above by Alif Maksura
            .replaceAll("\u064A", "\u0649")
            .replaceAll("\u0626", "\u0649")

            // Replace Alifs with Hamza Above/Below
            // and with Madda Above by Alif
            .replaceAll("\u0622", "\u0627")
            .replaceAll("\u0623", "\u0627")
            .replaceAll("\u0625", "\u0627")
            // .replaceAll(/[0-9]/g, "")
            .replace(regex, "")
    );
};
