export enum EnumLoginState {
    LoggedIn = 'LoggedIn',
    LoggedOut = "LoggedOut"
}

export interface RegisterDeviceResponse {
    t: number,
    k: string,
    deviceUUID: string
}

export interface CompleteProfile {
    date_of_birth_filled: boolean;
    email_filled: boolean;
    gender_filled: boolean;
    number_of_days: number;
    per_month: number;
}

export type UserIdentity = {
    id: number;
    M_email?: string;
    is_reported?: boolean;
    M_new_email?: string;
    M_user_name?: string;
    lang?: string;
    isBlocked?: boolean;
    member_verification?: number;
    memberSocial?: Array<any>;
    show_sms_verification_on_registration?: boolean;
    membersDetails?: {
        members_id?: number;
        authorised_seller?: boolean;
        show_shop_badge?: number;
        buy_now?: number;
    };
    limitAccountReport?: {
        membership_label?: string;
        membership_type?: string;
        liveAdsLimit?: number;
        numberOfPosts?: number;
        duration?: number;
        start_date?: number;
        expiration_date?: null | number;
        daysLeft?: string;
        number_of_remaining?: number;
    };
    hide_contact_info?: number;
    gender?: number;
    birthday?: string;
    member_type?: number;
    record_insert_date_timestamp?: number;
    record_insert_date?: string;
    first_name?: string;
    last_name?: string;
    full_name?: string;
    profile_picture?: string;
    profile_picture_large?: string;
    phone_number?: string;
    new_phone_number?: string;
    phone_country_code?: string;
    chatToken?: string;
    international_phone_number?: string;
    memberCountry?: string;
    paid_ads_count?: number;
    isDefaultAvatar?: boolean;
    dna?: string;
    adsFree?: boolean;
    is_pro_buyer?: boolean;
    post_views_limit?: number;
    search_limit?: number;
    memberLink?: string;
    isNewUser?: boolean;
    hasPassword?: boolean;
    name?: string;
    code?: number;
    status?: number;
    title?: string;
    message?: string;
    viewsCount?: number;
    responseTime?: string;
    followingsCount?: number;
    followersCount?: number;
    membership_type?: string;
    complete_profile?:CompleteProfile;
}

export interface Index {
    init(domainName: "opensooq.com" | string, rootElement: string, language: string, country: string, platform: string, deviceUUID: string, cityId: number): Promise<{
        "state": EnumLoginState
    }>;

    getToken(authenticated: boolean,verified?:boolean): Promise<string>;

    authenticate(redirectUrl: string): void;

    getIdentity(): Promise<UserIdentity | undefined>;

    destroy(): Promise<void>;

    changePassword(): Promise<void>


}

