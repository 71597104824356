import {isBrowser, parseCookies, setCookie} from "../helpers";

const UTM_SOURCE = "utm_source";
const UTM_CAMPAIGN = "utm_campaign";
const UTM_MEDIUM = "utm_medium";

const re_utm_source = new RegExp("utm_source=([^&]+)");
const re_utm_medium = new RegExp("utm_medium=([^&]+)");
const re_utm_campaign = new RegExp("utm_campaign=([^&]+)");

interface IUTMs {
    utm_source: string | "organic";
    utm_medium: string | "native";
    utm_campaign: string | "empty";
}

export const parseUTMs = (): IUTMs => {
    if (!isBrowser()) return;
    const cookies = parseCookies();
    const {search, hash} = window.location;
    let utm_source =
        search.match(re_utm_source)?.[1] ??
        hash.match(re_utm_source)?.[1] ??
        cookies[UTM_SOURCE] ??
        "organic";

    let utm_medium =
        search.match(re_utm_medium)?.[1] ??
        hash.match(re_utm_medium)?.[1] ??
        cookies[UTM_MEDIUM] ??
        "native";

    let utm_campaign =
        search.match(re_utm_campaign)?.[1] ??
        hash.match(re_utm_campaign)?.[1] ??
        cookies[UTM_CAMPAIGN] ??
        "empty";

    if (utm_source) {
        setCookie(UTM_SOURCE, utm_source);
    }
    if (utm_medium) {
        setCookie(UTM_MEDIUM, utm_medium);
    }
    if (utm_campaign) {
        setCookie(UTM_CAMPAIGN, utm_campaign);
    }

    return {utm_medium, utm_source, utm_campaign};
};
