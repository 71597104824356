import {useState, useEffect} from 'react';
import {ICountryAsset} from "../models/Assets/ICountryAsset";
import envConfig from "../env.config";
import {__getApiClient} from "../utils/misc/fetchClient";
import {ICityLiteAsset} from "../models/Assets/ICityAsset";
import {ssoConfigurations} from "../dataLayer/apisWithoutCache/slr/slrConfiguration";

function useGetEssentialDataAndTranslations(theme) {
    const [translations, setTranslations] = useState(null);
    const [countries, setCountries] = useState<ICountryAsset[]>([]);
    const [cities, setCities] = useState<ICityLiteAsset[]>([]);
    const [ssoConf, setConf] = useState<any>(null);
    const getEssentialData = async () => {
        const countriesPromise = __getApiClient<ICountryAsset[]>(envConfig.API_URL_CLIENT + `/countries/phone-codes`)
            .then((res) => {
                setCountries(res);
            });
        const citiesPromise = __getApiClient<{ id: number, name_english: string }[]>(envConfig.API_URL_CLIENT + `/cities?fields=id,name_english`)
            .then((res) => {
                setCities(res['items']);
            });
        const ssoConfiguration =  ssoConfigurations().then(res=> setConf(res));
        await Promise.all([countriesPromise, citiesPromise , ssoConfiguration]);
    }

    useEffect(() => {
        try {
            const translations = require(`../translations/${theme.locale}`);
            setTranslations(translations?.default);
        } catch (error) {
            console.error('error while importing translation', error);
            setTranslations(null);
        }
        getEssentialData().catch((e) => {
            console.log('e->', e.message)
        })

    }, [theme.locale]);

    return {translations, countries, cities ,ssoConf };
}

export default useGetEssentialDataAndTranslations;