import {API_HEADERS_CONSTANTS} from "../../constants";
import {Jwt} from "../../jwt";
import {getQueryStringValue, jsonSafeGet, parseCookies} from "../../helpers";
import envConfig from "../../env.config";
import {UserIdentity} from "../../interfaces";
import Auth from "../../auth";
import {generateABBucket} from "../../helpers/generateAbbucket";

type Method = "GET" | "POST" | "PUT" | "DELETE";


interface IFetchAtClientSideProps {
    url: string;
    method?: Method;
    body?: any;
    type?: string;
    abortController?: any;
    checkErrCallback?: (response) => boolean;
    shouldHandle401?: boolean;
    shouldRetry?: boolean
}

async function handle401Error(): Promise<void> {
    await Auth.getInstance().destroy();
    await Auth.getInstance().setAuth();
}

export const getApiHeaders = async () => {
    // const { authToken, source, deviceUUID } =
    const cookies = parseCookies()
    const generatedABBucketFromDeviceUUID = generateABBucket();
    const userAbBucket = cookies?.["userABBucket"] || generatedABBucketFromDeviceUUID;
    const jwt = new Jwt();

    const jwt_data = Auth.getInstance().auth
    //todo fix static aud

    const auth = Auth.getInstance()

    const aud = auth.platform || getQueryStringValue('platform')
    //todo fix user identity
    const userInfo = jsonSafeGet<UserIdentity>(cookies[envConfig.COOKIES_PREFIX + 'userInfo'])

    const token = await jwt.generate_jwt(jwt_data.k,
        {
            at0: jwt_data.t,
            aud,
            sub: userInfo && userInfo.id ? userInfo.id : jwt_data.deviceUUID
        });

    let headers = {
        source: aud,
        country: auth.country || getQueryStringValue('country'),
        "accept-language": auth.language || getQueryStringValue('language'),
        "release-version": API_HEADERS_CONSTANTS.RELEASE_VERSION,
        "abBucket" : userAbBucket
    };

    headers = {
        ...headers,
        ...{
            Authorization: "Bearer " + token,
            "x-tracking-uuid": jwt_data.deviceUUID,
        },
    };

    return headers;
};

export function _format_api_error(errorMessage, statusCode) {
    return {
        ...errorMessage,
        isValidationError: () => statusCode === 422,
        toValidationErrorMsg: () => {
            if (Array.isArray(errorMessage)) {
                return errorMessage.reduce((state, currentVal) => {
                    state[currentVal.field] = currentVal.message;
                    return state;
                }, {});
            } else {
                return errorMessage;
            }
        },
    };
}

async function __fetch_with_error(
    url: string,
    abortController?: any,
    headers = {},
    body: any = null,
    method: "POST" | "GET" = "GET",
    shouldHandle401: boolean = true,
    shouldRetry: boolean = true
) {
    const config = {
        method,
        ...(method === "POST" ? {body: JSON.stringify(body)} : {}),
        // credentials: "include",
        signal: undefined,
        headers,
    };
    if (abortController) {
        config.signal = abortController.signal;
    }
    // @ts-ignore
    const rawResponse = await fetch(url, config);
    const json = await rawResponse.json();
    if (rawResponse.status === 401 && shouldHandle401) {
        await handle401Error();
        if (shouldRetry) {
            const newHeaders = await getApiHeaders();
            return __fetch_with_error(
                url,
                abortController,
                newHeaders,
                body,
                method,
                false
            );
        } else return
    }
    if (rawResponse.status >= 400) {
        return Promise.reject(_format_api_error(json, rawResponse.status));
    }
    return json;
}

export async function __getApiClient<T>(
    url: string,
    abortController?: any,
    shouldRetry?: boolean
): Promise<T> {
    const generatedABBucketFromDeviceUUID = generateABBucket();
    const cookies = parseCookies();
    const userAbBucket = cookies?.["userABBucket"] || generatedABBucketFromDeviceUUID;

    if (!url.includes('abBucket=')) {
        url += (url.includes('?') ? '&' : '?') + `abBucket=${encodeURIComponent(userAbBucket)}`;
    }

    const headers = await getApiHeaders();
    return __fetch_with_error(url, abortController, headers, null, "GET", true, shouldRetry);
}

export function __getAjaxClient<T>(
    url: string,
    abortController?: any
): Promise<T> {
    url = `${envConfig.AJAX_API}${url}`;
    return __fetch_with_error(url, abortController);
}

export function __postAjaxClient<T>(
    url: string,
    body: any,
    abortController?: any
): Promise<T> {

    url = `${envConfig.NODE_API_URL_CLIENT}${url}`

    return __fetch_with_error(url, abortController, {
        "content-type": "application/json",
    }, body, "POST");
}

export async function fetchAtClientSide<T>({
                                               url,
                                               body,
                                               method = "GET",
                                               type,
                                               abortController = null,
                                               checkErrCallback = (response) => response.status >= 400,
                                               shouldRetry = true
                                           }: IFetchAtClientSideProps): Promise<T | Error> {
    const generatedABBucketFromDeviceUUID = generateABBucket();
    const cookies = parseCookies();
    const userAbBucket = cookies?.["userABBucket"] || generatedABBucketFromDeviceUUID;

    if (url[0] === "/") {
        url = url.substring(1, url.length);
    }
    url = `${envConfig.API_URL_CLIENT}/${url}`;

    if (!url.includes('abBucket=')) {
        url += (url.includes('?') ? '&' : '?') + `abBucket=${encodeURIComponent(userAbBucket)}`;
    }

    if (method == "GET") {
        return __getApiClient(url, abortController, shouldRetry);
    } else {
        const headers = await getApiHeaders();
        if (type?.toUpperCase() !== "FILE") {
            headers["Content-Type"] = "application/json";
        }
        // if (type?.toUpperCase() === "SLR_OTP") {
        //     headers["abBucket"] = 1;
        // }
        return new Promise((resolve, reject) => {
            const config = {
                method,
                body: type?.toUpperCase() === "FILE" ? body : JSON.stringify(body),
                cache: "no-cache",
                // credentials: "include",
                signal: undefined,
                headers,
            };
            if (abortController) {
                config.signal = abortController.signal;
            }
            // @ts-ignore
            fetch(url, config)
                .then(async (response) => {
                    const json = await response.json();
                    if (checkErrCallback(response)) {
                        return reject(_format_api_error(json, response.status));
                    }
                    resolve(json);
                })
                .catch((error) => {
                    console.error("fetchAtClientSide Error:", method, url, error);
                    reject(error as Error);
                });
        });
    }
}
