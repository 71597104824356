import { AuthTicketType } from "../dataLayer/cookiesAndLocalStorage/localStorageMember";
import {__postAjaxClient} from "../utils/misc/fetchClient";
import {getQueryStringValue, jsonSafeGet, parseCookies, setCookie} from "./index";
import {CookiesConstants} from "../constants";
import {RegisterDeviceResponse} from "../interfaces";


export const generateRequiredCookiesAndGetToken = async (platform): Promise<RegisterDeviceResponse> => {
    const cookies = parseCookies();
    const authV2Cookies = cookies[CookiesConstants.AUTH_V2];

    let authV2 = authV2Cookies ? jsonSafeGet<RegisterDeviceResponse>(authV2Cookies) : undefined
    if (!authV2) {
        // 2
        const authV1Cookies = cookies[CookiesConstants.AUTH]
        const authV1 = authV1Cookies ? jsonSafeGet<AuthTicketType>(authV1Cookies) : undefined
        const deviceUUID = cookies[CookiesConstants.DEVICE_UUID];
        if (authV1 && deviceUUID) {
            // 3 set Auth 2 cookie
            authV2 = {
                t: authV1.t,
                k: authV1.k,
                deviceUUID
            }
            setCookie(CookiesConstants.AUTH_V2, JSON.stringify(authV2));
        } else {
            // 4 call register device
            authV2 = await __postAjaxClient<RegisterDeviceResponse>(`/register-device`, {
                platform: platform || getQueryStringValue('platform'),
                deviceUUID,
            })

            setCookie(CookiesConstants.AUTH_V2, JSON.stringify(authV2))
            setCookie(CookiesConstants.DEVICE_UUID, authV2.deviceUUID)
        }
        return authV2
    }


    return authV2;
};
