import {b64_safe, hmac, atob64, rnd} from "./helpers";

interface JWTBasic {
    aud: string
    sub: string | number
    at0: number
}

interface JWTPayload extends JWTBasic {
    rnd: string;
    exp: number;
}

export class Jwt {
    //TODO: to pass audience secret
    public audience_secret: string = "";

    public async generate_jwt(tgt: string, payloadBasic: JWTBasic): Promise<string> {
        if (!payloadBasic.sub || !payloadBasic.aud || !payloadBasic.at0) {
            throw new Error("missing payload details");
        }
        const payload = payloadBasic as JWTPayload;
        const headerEncoded = this.getHeaderPart();
        const payloadEncoded = this.getPayloadPart(payload);
        const header_dot_payload = `${headerEncoded}.${payloadEncoded}`;
        const secret = await this.generate_token_secret(payload, tgt, this.audience_secret);
        const signature = b64_safe(await hmac(secret, header_dot_payload));
        return `${header_dot_payload}.${signature}`;
    }

    public async generate_token_secret(payloadBasic: JWTPayload, tgt: string, audience_secret: string) {
        const {aud, rnd, at0} = payloadBasic;
        const key = tgt;
        const msg = `${aud}.${audience_secret}.${rnd}.${at0}`;
        // console.log(aud, rnd, at0, tgt, "sec", audience_secret);
        return b64_safe(await hmac(key, msg));
    }

    private getHeaderPart() {
        const header = {alg: "HS256", typ: "JWT"};
        return b64_safe(atob64(JSON.stringify(header)));
    }

    private getPayloadPart(payloadBasic: Partial<JWTPayload>) {
        const payload = payloadBasic as JWTPayload;
        const is_logged_in: Boolean = (typeof (payload.sub) === 'number');
        payload.rnd = is_logged_in ? rnd(5) : rnd(10);
        payload.exp = parseInt(String(Date.now() / 1000)) + 300;
        return b64_safe(atob64(JSON.stringify(payload)));
    }
}
