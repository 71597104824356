import { CookiesConstants } from "../constants";
import {jsonSafeGet, parseCookies} from "./index";
import MD5 from "crypto-js/md5";
import {AuthTicketType} from "../dataLayer/cookiesAndLocalStorage/localStorageMember";

export const generateABBucket = () => {
  const cookies = parseCookies();
  const deviceUUID = jsonSafeGet<AuthTicketType>(cookies[CookiesConstants.AUTH_V2], {})?.device_uuid || cookies[CookiesConstants.DEVICE_UUID];

  if (!deviceUUID || deviceUUID.length !== 36) {
    console.log("Invalid deviceUUID: Must be 36 characters long");
    return null;
  }

  const lastTwoChars = MD5(deviceUUID).toString().slice(-2);
  const hexValue = parseInt(lastTwoChars, 16);
  const bucketNumber = Math.ceil((hexValue / 255.0) * 10) || 1;

  return String(bucketNumber);
};
