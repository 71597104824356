import {isClientSide} from "../helpers";

function localStorageUtility() {
    const Window = isClientSide()
        ? window
        : {
            localStorage: {
                getItem: (k: string) => null,
                setItem: (k: string, val: any) => null,
                removeItem: (key: string) => null,
            },
        };

    const setItem = (key: string, value: any) => {
        return Window.localStorage.setItem(key, JSON.stringify(value));
    }

    const getItem = <T>(key: string): T => {
        const val = Window.localStorage.getItem(key);
        return val ? JSON.parse(val) : null;
    };
    const removeItem = (key: string) => {
        Window.localStorage.removeItem(key);
    };
    return {setItem, getItem, removeItem};
}

export default localStorageUtility;
